import React, { Component, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import '../style.css'
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorPage from "./ErrorPage";
import { isEmpty } from "lodash";

const SearchBar = ({ data, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        onSearch(searchTerm);
    }, [searchTerm]);

    return (
        <div className="search-bar">
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-box"
            />
            <img src={process.env.PUBLIC_URL + "/search.svg"} class="search-icon" alt="search-icon"></img>
        </div>
    );
};



const LearnerCourses = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');

    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [errorPage, setErrorPage] = useState(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                if(!isEmpty(email)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/LearnerTranscript?email=${email}`);
                    const responseData = data.data;

                    setData(responseData);
                } else {
                    setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorPage(true);
            }
        };

        fetchCourses();
    }, []);

    useEffect(() => {
        if (data && data.courses) {
            setFilteredData(data.courses);
        }
    }, [data]);

    const handleSearch = (searchTerm) => {
        if (!searchTerm || !data || !data.courses) {
            setFilteredData(data?.courses);
            return;
        }

        const filteredResults = data.courses.filter((course) =>
            course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filteredResults);
    };

    const convertDate = (dateString) => {
        const splitDate = dateString.split('T')[0];
        const year = splitDate.split('-')[0];
        
        const dateType = new Date(dateString);  // 2009-11-10
        const month = dateType.toLocaleString('default', { month: 'short' });
    
        const date = splitDate.split('-')[2];
    
        const formattedDate = `${month} ${date}, ${year}`;
        return formattedDate;
    }

    if (errorPage) {
        return <ErrorPage/>
    } else {
        return (
            <>
                <head>
                    <title>Unify</title>
                </head>
                <body class="course-screen">
                    <div class="top-section pt-5">
                        {/* <div class="points-bar d-flex justify-content-center">
                            <img class="coin-icon" src={process.env.PUBLIC_URL + "/coin.svg"} alt="coins-icon" />
                            <div class="points-wrp">
                                <div class="point-text">Point Earned</div>
                                <div class="Points-earned">{data?.pointsEarned}</div>
                            </div>
                        </div> */}
                        <div class="profile-section d-flex">
                            <div>
                                <img src={data?.avatarUrl} class="user-icon" alt="user-icon" />
                            </div>
                            <div class="mx-2">
                                <div class="user-name">{data?.name}</div>
                                <div class="position">{data?.profile}</div>
                                <div class="divider"></div>
                                <div class="st-code"><span class="st-cd">Station Code:</span><span class="code">{data?.stationCode}</span></div>
                            </div>
                            <div class="right-block">
                                <div class="position">PPR:</div>
                                <div class="prr-code">{data?.ppr}</div>
                            </div>
                        </div>
                    </div>
                    <div class="course-details">
                        <div class="heading mb-3">Courses</div>
                        <SearchBar data={data} onSearch={handleSearch} />
                        <ul className="ul">
                            {filteredData && filteredData.length > 0 ? (
                                filteredData.map((item, index) => (
                                    <li key={index} class="box d-flex mt-3">
                                        <div class="img-wrp">
                                            <img src={process.env.PUBLIC_URL + "/users-connect-tick.svg"} alt="user-connect" class="" />
                                        </div>
                                        <div class="content-wrp">
                                            <div class="course-title mb-2">{item.courseName}</div>
                                            {
                                                item.state === 'ENROLLED' ? <div class="course-status-enrolled mb-2">Enrolled</div> : (item.state === 'STARTED' ? <div class="course-status-inprogress mb-2">In Progress</div> : <div class="course-status-completed mb-2">Completed</div>)
                                            }
                                            {item.dateCompleted ?
                                            <div>
                                                <img src={process.env.PUBLIC_URL + "/date.svg"} />
                                                <span class="date">Completion Date:</span>
                                                <span class="dt">{convertDate(item.dateCompleted)}</span> 
                                            </div>: '' }
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <div>No matching courses found.</div>
                            )}
                        </ul>
                    </div>
                </body>
            </>
        ) 
    }
}

export default LearnerCourses