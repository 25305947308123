import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Header from './Header';
import ScheduledReportPopUp from './ScheduledReportPopUp';
import ReportMessage from "./ReportMessage";
import GridData from "./GridData";
import ErrorPage from "../ErrorPage";
import './style.css';
import { isEmpty, isNull, uniq, orderBy } from "lodash";
import Papa from 'papaparse';

const SearchReport = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const token = queryParams.get('authToken');
    const scheduleJobId = queryParams.get('jobId') || null;

    const dropdownRef = useRef(null);
    const nameSuggestionRef = useRef(null);
    const entitySuggestionRef = useRef(null);
    const customerCodeSuggestionRef = useRef(null);
    const losSuggestionRef = useRef(null);
    const uuidSuggestionRef = useRef(null);
    const stationCodeSuggestionRef = useRef(null);
    const managerNameSuggestionRef = useRef(null);
    const uusSuggestionRef = useRef(null);

    const [displaySearchFields, setDisplaySearchFields] = useState(true);
    const [fetchScheduledReportPopUp, showFetchScheduledReportPopUp] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [userPreferenceAvailability, setUserPreferenceAvailability] = useState(false);
    const [courseFilterCriteria, setCourseFilterCriteria] = useState();
    const [displaySearchCourseDropdown, setDisplaySearchCourseDropdown] = useState(false);
    const [generatingReportMsg, showGeneratingReportMsg] = useState(false);
    const [onDemandReportId, setOnDemandReportId] = useState('');
    const [onDemandReportData, setOnDemandReportData] = useState([]);
    const [polling, setPolling] = useState(false);
    const [nothingMsg, showNothingMsg] = useState(true);
    const [viewReportMsg, showViewReportMsg] = useState(false);
    const [emptyReportMsg, showEmptyReportMsg] = useState(false);
    const [displayGridWithData, setDisplayGridWithData] = useState(false);
    const [exportToCSV, setExportToCSV] = useState(false);

    const [fetchFromScheduleReportData, setFetchFromScheduleReportData] = useState();

    const [searchCourseNames, setSearchCourseNames] = useState([]);
    const [searchCourse, setSearchCourse] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [searchEntity, setSearchEntity] = useState('');
    const [searchCustomerCode, setSearchCustomerCode] = useState('');
    const [searchLosDesc, setSearchLosDesc] = useState('');
    const [searchUUID, setSearchUUID] = useState('');
    const [searchStationCode, setSearchStationCode] = useState('');
    const [searchManagerName, setSearchManagerName] = useState('');
    const [searchUnifiUserStatus, setSearchUnifiUserStatus] = useState('');

    const [viewReportUrl, setViewReportUrl] = useState('');

    const [csvData, setCsvData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const [displayNameSuggestions, setDisplayNameSuggestions] = useState(false);
    const [nameSuggestions, setNameSuggestions] = useState([]);
    const [displayEntitySuggestions, setDisplayEntitySuggestions] = useState(false);
    const [entitySuggestions, setEntitySuggestions] = useState([]);
    const [displayCustomerCodeSuggestions, setDisplayCustomerCodeSuggestions] = useState(false);
    const [customerCodeSuggestions, setCustomerCodeSuggestions] = useState([]);
    const [displayLOSDescSuggestions, setDisplayLOSDescSuggestions] = useState(false);
    const [losDescSuggestions, setLosDescSuggestions] = useState([]);
    const [displayUuidSuggestions, setDisplayUuidSuggestions] = useState(false);
    const [uuidSuggestions, setUuidSuggestions] = useState([]);
    const [displayStationCodeSuggestions, setDisplayStationCodeSuggestions] = useState(false);
    const [stationCodeSuggestions, setStationCodeSuggestions] = useState([]);
    const [displayManagerNameSuggestions, setDisplayManagerNameSuggestions] = useState(false);
    const [managerNameSuggestions, setManagerNameSuggestions] = useState([]);
    const [displayUnifiUserStatusSuggestions, setDisplayUnifiUserStatusSuggestions] = useState(false);
    const [unifiUserStatusSuggestions, setUnifiUserStatusSuggestions] = useState([]);

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [tableData, setTableData] = useState([]);

    const [dynamicColumns, setDynamicColumns] = useState([]);

    useEffect(() => {
        if (isEmpty(userId) || isEmpty(token)) {
            setErrorPage(true);
        }
    }, [userId, token]);

    useEffect(() => {
        const getUserPreferences = async () => {
            try {
                if (!isEmpty(userId)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Preference/${userId}`);
                    const responseData = data.data;
                    if (!isEmpty(responseData)) {
                        setUserPreferenceAvailability(true);
                        setSearchName(responseData.Name);
                        setSearchEntity(responseData.EntityName)
                        setSearchCustomerCode(responseData.CustomerCode)
                        setSearchLosDesc(responseData.LineOfServiceDescription)
                        setSearchUUID(responseData.UUID)
                        setSearchStationCode(responseData.StationCode)
                        setSearchManagerName(responseData.ManagerName)
                        setSearchUnifiUserStatus(responseData.UnifiUserStatus)
                        setSearchCourseNames(responseData.Courses)
                    }
                } else {
                    setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching page load data:', error);
                setErrorPage(true);
            }
        };
        getUserPreferences();
    }, []);

    useEffect(() => {
        const getFilterCriteria = async () => {
            try {
                if (!isEmpty(userId)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/filtercriteria?token=${token}`);
                    const responseData = data.data;
                    setNameSuggestions(responseData.Name);
                    setEntitySuggestions(uniq(responseData["Entity Name"]));
                    setCustomerCodeSuggestions(uniq(responseData["Customer Code"]));
                    setLosDescSuggestions(uniq(responseData["Line Of Service Desc"]));
                    setUuidSuggestions(uniq(responseData["UUID"]));
                    setStationCodeSuggestions(uniq(responseData["Station Code"]));
                    setManagerNameSuggestions(uniq(responseData["Manager Name"]));
                    setUnifiUserStatusSuggestions(uniq(responseData["Unifi User Status"]));
                } else {
                    setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching page load data:', error);
                setErrorPage(true);
            }
        };
        const getFilterCriteriaCourse = async () => {
            try {
                if (!isEmpty(userId)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/FilterCriteriaCourse?token=${token}`);
                    setCourseFilterCriteria(splitArray(data.data.Course, 3));
                } else {
                    setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching page load data:', error);
                setErrorPage(true);
            }
        };
        getFilterCriteria();
        getFilterCriteriaCourse();
    }, []);

    const splitArray = (originalArray, numberOfArrays) => {
        const resultArrays = new Array(numberOfArrays).fill().map(() => []);

        for (let i = 0; i < originalArray.length; i++) {
            const currentArrayIndex = i % numberOfArrays;
            resultArrays[currentArrayIndex].push(originalArray[i]);
        }
        return resultArrays;
    };

    const handleFetchScheduledReportsClick = () => {
        showFetchScheduledReportPopUp(true);
    };

    const handleSearchCourseClick = () => {
        setDisplaySearchCourseDropdown(true);
    };

    useEffect(() => {
        // Event listener to close the dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDisplaySearchCourseDropdown(false);
            }
            if (nameSuggestionRef.current && !nameSuggestionRef.current.contains(event.target)) {
                setDisplayNameSuggestions(false);
            }
            if (entitySuggestionRef.current && !entitySuggestionRef.current.contains(event.target)) {
                setDisplayEntitySuggestions(false);
            }
            if (customerCodeSuggestionRef.current && !customerCodeSuggestionRef.current.contains(event.target)) {
                setDisplayCustomerCodeSuggestions(false);
            }
            if (losSuggestionRef.current && !losSuggestionRef.current.contains(event.target)) {
                setDisplayLOSDescSuggestions(false);
            }
            if (uuidSuggestionRef.current && !uuidSuggestionRef.current.contains(event.target)) {
                setDisplayUuidSuggestions(false);
            }
            if (stationCodeSuggestionRef.current && !stationCodeSuggestionRef.current.contains(event.target)) {
                setDisplayStationCodeSuggestions(false);
            }
            if (managerNameSuggestionRef.current && !managerNameSuggestionRef.current.contains(event.target)) {
                setDisplayManagerNameSuggestions(false);
            }
            if (uusSuggestionRef.current && !uusSuggestionRef.current.contains(event.target)) {
                setDisplayUnifiUserStatusSuggestions(false);
            }
        };
        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (polling) {
            // Set up polling interval (e.g., every 5 seconds)
            const pollingInterval = setInterval(fetchReportById, 5000);

            // Clean up the interval on component unmount
            return () => clearInterval(pollingInterval);
        }
    }, [polling]); // Empty dependency array to run only once on mount

    const fetchReportById = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/getReport/${onDemandReportId}`);
            const result = response.data;
            setOnDemandReportData(result);

            // Check the condition here
            if (result.ReportStatus === 'Completed' && !isEmpty(result.ReportUrl)) {
                setPolling(false);
                showGeneratingReportMsg(false);
                showViewReportMsg(true);
                setViewReportUrl(result.ReportUrl);
            } else if (result.ReportStatus === 'Completed' && isEmpty(result.ReportUrl)) {
                setPolling(false);
                showGeneratingReportMsg(false);
                showEmptyReportMsg(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setPolling(false);
        }
    };

    useEffect(() => {
        const getScheduleJobById = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Schedules/${userId}?jobId=${scheduleJobId}`);
                const result = response.data;

                if (!isEmpty(result)) {
                    setUserPreferenceAvailability(true);
                    setSearchName(result.Name);
                    setSearchEntity(result.EntityName)
                    setSearchCustomerCode(result.CustomerCode)
                    setSearchLosDesc(result.LineOfServiceDescription)
                    setSearchUUID(result.UUID)
                    setSearchStationCode(result.StationCode)
                    setSearchManagerName(result.ManagerName)
                    setSearchUnifiUserStatus(result.UnifiUserStatus)
                    if(result.Courses.length > 0) {
                        let courseNames = [];
                        result.Courses.map((ele) => {
                            courseNames.push(ele.name);
                        });
                        setSearchCourseNames(courseNames)
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorPage(true);
            }
        };

        if (!isNull(scheduleJobId)) {
            getScheduleJobById();
        }
    }, [scheduleJobId]); // Empty dependency array to run only once on mount

    const handleGenerateReportClick = async () => {
        if(searchCourseNames.length > 0) {
            try {
                const body = {
                    "name": searchName,
                    "entityName": searchEntity,
                    "customerCode": searchCustomerCode,
                    "lineOfServiceDescription": searchLosDesc,
                    "uuid": searchUUID,
                    "stationCode": searchStationCode,
                    "managerName": searchManagerName,
                    "unifiUserStatus": searchUnifiUserStatus,
                    "courses": searchCourseNames
                };
    
                const userPreferenceConfig = {
                    url: `${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Preference/${userId}`,
                    method: userPreferenceAvailability ? 'PUT' : 'POST',
                    data: body,
                };
    
                await axios.request(userPreferenceConfig);
                // call generate report api
    
                let courseIds = [];
                if (!isEmpty(searchCourse)) {
                    searchCourse.map((ele) => {
                        courseIds.push(ele.name);
                    });
                };
    
                const generateReportBody = {
                    "ReportTitle": "",
                    "Course": searchCourseNames,
                    "Name": searchName,
                    "EntityName": searchEntity,
                    "CustomerCode": searchCustomerCode,
                    "LineOfServiceDesc": searchLosDesc,
                    "uuid": searchUUID,
                    "StationCode": searchStationCode,
                    "Manager_Name": searchManagerName,
                    "UnifiUserStatus": searchUnifiUserStatus,
                    "ScheduledDay": "",
                    "EmailIds": "",
                    "ReportType": "OnDemand"
                    // "User_Unique_ID": "",
                };
    
                const generateReportConfig = {
                    url: `${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Report/${userId}`,
                    method: 'POST',
                    data: generateReportBody,
                };
    
                const generateReportResult = await axios.request(generateReportConfig);
                setOnDemandReportId(generateReportResult.data.reportId);
                setPolling(true);
                showNothingMsg(false);
                setDisplayErrorMessage(false);
                showGeneratingReportMsg(true);
            } catch (err) {
                console.log("Error: ", err.message);
                setErrorPage(true);
            }
        } else {
            showNothingMsg(false);
            setDisplayErrorMessage(true);
        }
    };

    const handleSearchCheckBoxClick = (e, value) => {
        if (searchCourse.includes(value)) {
            // If the value is already in the array, remove it
            setSearchCourse(searchCourse.filter(item => item !== value));
        } else {
            // If the value is not in the array, add it
            setSearchCourse([...searchCourse, value]);
        }
        if (searchCourseNames.includes(value.name)) {
            // If the value is already in the array, remove it
            setSearchCourseNames(searchCourseNames.filter(item => item !== value.name));
        } else {
            // If the value is not in the array, add it
            setSearchCourseNames([...searchCourseNames, value.name]);
        }
    }; 

    const handleClearAllClick = () => {
        setSearchCourse([]);
        setSearchCourseNames([]);
        setSearchName('');
        setSearchEntity('');
        setSearchCustomerCode('');
        setSearchLosDesc('');
        setSearchUUID('');
        setSearchStationCode('');
        setSearchManagerName('');
        setSearchUnifiUserStatus('');
    };

    const handleViewReportClick = async (reportUrl) => {
        showViewReportMsg(false);
        setDisplayGridWithData(true);
        setExportToCSV(true);
        setDynamicColumns(searchCourseNames);
        try {
            const response = await axios.get(reportUrl);
            const result = Papa.parse(response.data, {
                header: true, // Assuming the first row contains headers
            });
            setHeaders(result.meta.fields);
            setTableData(result.data);
            setCsvData(result.data);
        } catch (error) {
            console.error('Error fetching data from Azure Blob:', error);
            setErrorPage(true);
        }
    }

    const handleExportToCSVClick = async (reportUrl) => {
        const urlObject = new URL(reportUrl);
        const pathname = urlObject.pathname;
        const parts = pathname.split('/');
        const fileName = parts[parts.length - 1];

        try {
            const response = await axios.get(reportUrl, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            // Trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading CSV from Azure Blob:', error);
            setErrorPage(true);
        }
    };

    const getUserSchedules = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Schedules/${userId}`);
            setFetchFromScheduleReportData(orderBy(data.data, 'createdDate', 'desc'));
        } catch (error) {
            console.error('Error fetching page load data:', error);
            setErrorPage(true);
        }
    };

    useEffect(() => {
        getUserSchedules();
    }, []);

    const handleLoadScheduledReportClick = async (item) => {
        try {
            if (!isEmpty(item)) {
                showFetchScheduledReportPopUp(false);
                setUserPreferenceAvailability(true);

                if(item.Courses.length > 0) {
                    let courseNames = [];
                    item.Courses.map((ele) => {
                        courseNames.push(ele)
                    });
                    setSearchCourseNames(courseNames)
                }
                setSearchName(item.Name);
                setSearchEntity(item.EntityName)
                setSearchCustomerCode(item.CustomerCode)
                setSearchLosDesc(item.LineOfServiceDescription)
                setSearchUUID(item.UUID)
                setSearchStationCode(item.StationCode)
                setSearchManagerName(item.ManagerName)
                setSearchUnifiUserStatus(item.UnifiUserStatus)

                showNothingMsg(true);
                showViewReportMsg(false);
                showEmptyReportMsg(false);
                showGeneratingReportMsg(false);
                setExportToCSV(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorPage(true);
        }
    };

    const handleHideFilterClick = () => {
        setDisplaySearchFields(!displaySearchFields);
    }

    const filteredNameSuggestions = nameSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchName.toLowerCase())
    );

    const handleNameSuggestionClick = (suggestion) => {
        setSearchName(suggestion);
        setDisplayNameSuggestions(false);
    };

    const filteredEntitySuggestions = entitySuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchEntity.toLowerCase())
    );

    const handleEntitySuggestionClick = (suggestion) => {
        setSearchEntity(suggestion);
        setDisplayEntitySuggestions(false);
    };

    const filteredCustomerCodeSuggestions = customerCodeSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchCustomerCode.toLowerCase())
    );

    const handleCustomerCodeSuggestionClick = (suggestion) => {
        setSearchCustomerCode(suggestion);
        setDisplayCustomerCodeSuggestions(false);
    };

    const filteredLOSDescSuggestions = losDescSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchLosDesc.toLowerCase())
    );

    const handleLOSDescSuggestionClick = (suggestion) => {
        setSearchLosDesc(suggestion);
        setDisplayLOSDescSuggestions(false);
    };

    const filteredUuidSuggestions = uuidSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchUUID.toLowerCase())
    );

    const handleUuidSuggestionClick = (suggestion) => {
        setSearchUUID(suggestion);
        setDisplayUuidSuggestions(false);
    };

    const filteredStationCodeSuggestions = stationCodeSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchStationCode.toLowerCase())
    );

    const handleStationCodeSuggestionClick = (suggestion) => {
        setSearchStationCode(suggestion);
        setDisplayStationCodeSuggestions(false);
    };

    const filteredManagerNameSuggestions = managerNameSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchManagerName.toLowerCase())
    );

    const handleManagerNameSuggestionClick = (suggestion) => {
        setSearchManagerName(suggestion);
        setDisplayManagerNameSuggestions(false);
    };

    const filteredUnifiUserStatusSuggestions = unifiUserStatusSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(searchUnifiUserStatus.toLowerCase())
    );

    const handleUnifiUserStatusSuggestionClick = (suggestion) => {
        setSearchUnifiUserStatus(suggestion);
        setDisplayUnifiUserStatusSuggestions(false);
    };

    const handleSort = (column) => {
        let direction = 'asc';
        if (sortConfig.key === column && sortConfig.direction === 'asc') {
          direction = 'desc';
        }
        setSortConfig({ key: column, direction });
    
        const sortedData = [...csvData].sort((a, b) => {
          const aValue = a[column];
          const bValue = b[column];
    
          if (aValue < bValue) {
            return direction === 'asc' ? -1 : 1;
          } else if (aValue > bValue) {
            return direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
        setTableData(sortedData);
    };

    if (errorPage) {
        return <ErrorPage />
    } else {
        return (
            <>
                <head>
                    <title>Unify</title>
                    <link rel="stylesheet" href="./style.css" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <link href="https://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet" />
                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" crossorigin="anonymous" />
                </head>
                <body className="search-screen">
                    {
                        fetchScheduledReportPopUp && <div className="overlay" />
                    }
                    <Header />
                    <div className="search-section">
                        <div className="inner-wrapper">
                            <div className={displaySearchFields ? "" : "search-course-fields"}>
                                <div className="space-below searchcourse-input" ref={dropdownRef}>
                                    <input type="text" className="search-box col-lg-12" placeholder={searchCourseNames.length > 0 ? "" : "Search Course"} onClick={handleSearchCourseClick} /> <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} onClick={handleSearchCourseClick} />
                                    <div class="container-outer">
                                        <div class="container-inner chips">
                                            {searchCourseNames.length > 0 && searchCourseNames.map((ele) => (<span>{ele}</span>))}
                                        </div>
                                    </div>

                                    {displaySearchCourseDropdown && <div className="search-dropdown">
                                        {courseFilterCriteria && courseFilterCriteria.map((chunks, idx) => (
                                            <div className="fields-outer">
                                                {chunks.map((item, index) => (
                                                    <div className="field-wrp"><input type="checkbox" checked={searchCourseNames.includes(item.name)} onChange={(e) => handleSearchCheckBoxClick(e, item)} /> <span>{item.name}</span></div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                                <div className="d-flex justify-content-between space-below">
                                    <div className="search-outer" ref={nameSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Name" autocomplete="list" value={searchName} onChange={(e) => { setSearchName(e.target.value); setDisplayNameSuggestions(true) }}/>
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Name</label>
                                        {
                                            searchName && displayNameSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredNameSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleNameSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={entitySuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Entity Name" value={searchEntity} onChange={(e) => { setSearchEntity(e.target.value); setDisplayEntitySuggestions(true) }}/>
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Entity</label>
                                        {
                                            searchEntity && displayEntitySuggestions &&
                                            <div className="suggestions-container">
                                                {filteredEntitySuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleEntitySuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={customerCodeSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Customer Code" value={searchCustomerCode} onChange={(e) =>{ setSearchCustomerCode(e.target.value); setDisplayCustomerCodeSuggestions(true) }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Customer Code</label>
                                        {
                                            searchCustomerCode && displayCustomerCodeSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredCustomerCodeSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleCustomerCodeSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={losSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Line Of Service Description" value={searchLosDesc} onChange={(e) => { setSearchLosDesc(e.target.value); setDisplayLOSDescSuggestions(true); }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Line Of Service Description</label>
                                        {
                                            searchLosDesc && displayLOSDescSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredLOSDescSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleLOSDescSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between space-below">
                                    <div className="search-outer" ref={uuidSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search UUID" value={searchUUID} onChange={(e) => { setSearchUUID(e.target.value); setDisplayUuidSuggestions(true) }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>UUID</label>
                                        {
                                            searchUUID && displayUuidSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredUuidSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleUuidSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={stationCodeSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Station Code" value={searchStationCode} onChange={(e) => { setSearchStationCode(e.target.value); setDisplayStationCodeSuggestions(true) }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Station Code</label>
                                        {
                                            searchStationCode && displayStationCodeSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredStationCodeSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleStationCodeSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={managerNameSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Manager Name" value={searchManagerName} onChange={(e) => { setSearchManagerName(e.target.value); setDisplayManagerNameSuggestions(true) }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Manager Name</label>
                                        {
                                            searchManagerName && displayManagerNameSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredManagerNameSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleManagerNameSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    <div className="search-outer" ref={uusSuggestionRef}>
                                        <input type="text" className="search-box" placeholder="Search Unifi User Status" value={searchUnifiUserStatus} onChange={(e) => { setSearchUnifiUserStatus(e.target.value); setDisplayUnifiUserStatusSuggestions(true) }} />
                                        <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                        <label>Unifi User Status</label>
                                        {
                                            searchUnifiUserStatus && displayUnifiUserStatusSuggestions &&
                                            <div className="suggestions-container">
                                                {filteredUnifiUserStatusSuggestions.map((suggestion, index) => (
                                                    <div key={index} onClick={() => handleUnifiUserStatusSuggestionClick(suggestion)} className="suggestion">
                                                        {suggestion}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/schedule-file.svg"} /><span className="fetch-link" onClick={handleFetchScheduledReportsClick}>Fetch From Scheduled Reports</span></div>
                                <div className="d-flex">
                                    <div className="clear-btn btns d-flex align-items-center" onClick={handleClearAllClick}>Clear All</div>
                                    {
                                        (generatingReportMsg || viewReportMsg) ? <div className="gn-btn btns d-flex align-items-center btn-disabled"><img src={process.env.PUBLIC_URL + "/report-white.svg"} />Generate Report</div> : <div className="gn-btn btns d-flex align-items-center" onClick={handleGenerateReportClick}><img src={process.env.PUBLIC_URL + "/report-white.svg"} />Generate Report</div>
                                    }

                                    {
                                        exportToCSV ? <div className="ex-btn data-btn btns d-flex align-items-center" onClick={() => handleExportToCSVClick(viewReportUrl)}><img src={process.env.PUBLIC_URL + "/export-red.svg"} />Export to CSV</div> : <div className="ex-btn btns d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/export-icon.svg"} />Export to CSV</div>
                                    }
                                </div>
                            </div>
                            {
                                displaySearchFields ?
                                    <div className="toggle-filter d-flex align-items-center" onClick={handleHideFilterClick}>Hide Filters<img className="filter-arrow" src={process.env.PUBLIC_URL + "/filter-arrow.svg"} /></div> :
                                    <div className="toggle-filter-show d-flex align-items-center" onClick={handleHideFilterClick}>Show Filters<img className="filter-arrow" src={process.env.PUBLIC_URL + "/dwn-arrow.svg"} /></div>
                            }
                        </div>
                    </div>
                    {
                        nothingMsg && <ReportMessage messageType={'nothing'} />
                    }
                    {
                        displayErrorMessage && <ReportMessage messageType={'error'}/>
                    }
                    {
                        fetchScheduledReportPopUp && <ScheduledReportPopUp showScheduledReportPopUp={showFetchScheduledReportPopUp} popUpData={fetchFromScheduleReportData} onLoadClick={handleLoadScheduledReportClick} />
                    }
                    {
                        generatingReportMsg && <ReportMessage messageType={'generate'} />
                    }
                    {
                        viewReportMsg && <ReportMessage messageType={'view'} onViewReport={() => handleViewReportClick(viewReportUrl)} />
                    }
                    {
                        emptyReportMsg && <ReportMessage messageType={'empty'} />
                    }
                    {
                        displayGridWithData && <GridData csvData={tableData} headerData={headers} onSort={handleSort} dynamicCourseColumns={dynamicColumns}/>
                    }
                </body>
            </>
        )
    }
};

export default SearchReport;