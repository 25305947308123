import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const authToken = queryParams.get('authToken');
    let activePageNumber;

    if(location.pathname === '/reports') {
        activePageNumber = 1;
    } else if(location.pathname === '/reports/scheduled') {
        activePageNumber = 2;
    }

    const handleScheduledReportsClick = () => {
        navigate(`/reports/scheduled?userId=${userId}&authToken=${authToken}`);
    };

    const handleComplianceMatrixClick = () => {
        navigate(`/reports?userId=${userId}&authToken=${authToken}`);
    };

    return (
        <header className="header-section d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <div className="header-logo"><img className="logo" src={process.env.PUBLIC_URL + "/Unify-logo.png"} alt="header-logo" /></div>
                <div className="header-text no-brd">{activePageNumber === 1 ? "Compliance Matrix" : "Scheduled Reports"}</div>
            </div>
            <div className="d-flex mr-140">
                <div className={activePageNumber === 1 ? "header-text active" : "header-text"} onClick={handleComplianceMatrixClick}>Compliance Matrix Report</div>
                <div className={activePageNumber === 2 ? "header-text active sr" : "header-text sr"} onClick={handleScheduledReportsClick}>Scheduled Reports</div>
            </div>
        </header>
    )
}

export default Header;