import React from "react";

const ReportMessage = ({ messageType, onViewReport }) => {

    if(messageType === 'generate') {
        return (
            <div className="item-display rp-scr">
                <img className="hourglass" src={process.env.PUBLIC_URL + "/Loader-Animation-2.gif"}/>
                <div className="gn-data">
                    <div>Generating reports, please wait a moment.</div>
                    <div className="gn-data">Thank you for your patience!</div>
                </div>
            </div>
        )
    } else if(messageType === 'nothing') {
        return (
            <div className="item-display">
                <div className="no-data">Nothing to Display!</div>
                <div className="info-text">Select items by clicking on your choices.</div>
            </div>
        )
    } else if(messageType === 'view') {
        return (
            <div className="item-display rp-scr">
                <div className="gn-data">Your report is ready. Tap to view details.</div>
                <div className="button-primary d-flex align-items-center view-rpt-btn"><img className="load-icon" src={process.env.PUBLIC_URL + "/report-white.svg"}/><span onClick={onViewReport}>View Report</span></div>
            </div>
        )
    } else if(messageType === 'empty') {
        return (
            <div className="item-display">
                <div className="no-data">Your report has no data!</div>
            </div>
        )
    } else if(messageType === 'error') {
        return (
            <div className="item-display">
                <div className="no-data error">Please select atleast one course and try again!</div>
            </div>
        )
    }
}

export default ReportMessage;