import React from 'react';
import LearnerComponent from './components/Learner';
import LearnerCourses from './components/LearnerCourses';
import SearchReport from './components/Reports/SearchReport';
import ScheduledReport from './components/Reports/ScheduledReport';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Routes>
        <Route path="/" element={<LearnerComponent/>} />
        <Route path="/transcript" element={<LearnerCourses/>} />
        <Route path="/reports" element={<SearchReport/>} />
        <Route path="/reports/scheduled" element={<ScheduledReport/>} />
        <Route path="/error" element={<ErrorPage/>} />

        </Routes>
      </Router>
    );
  }
}

export default App;