import React, { useState, useEffect } from "react";
import Header from './Header';
import ScheduleNewReport from "./ScheduleNewReport";
import ConfirmationPopUp from "./ConfirmationPopUp";
import ErrorPage from "../ErrorPage";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty, orderBy } from "lodash";

const ScheduledReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const authToken = queryParams.get('authToken');

    const [errorPage, setErrorPage] = useState(false);

    const [scheduleReportPopUp, showScheduleReportPopUp] = useState(false);
    const [confirmationPopUp, showConfirmationPopUp] = useState(false);
    const [dataToBeDeleted, setDataToBeDeleted] = useState('');
    const [scheduleReportPopUpDataForUpdate, setScheduleReportPopUpDataForUpdate] = useState();
    const [reportTableData, setReportTableData] = useState();

    const handleScheduleNewReportClick = () => {
        showScheduleReportPopUp(true);
    };

    const handleEditScheduleClick = (item) => {
        showScheduleReportPopUp(true);
        setScheduleReportPopUpDataForUpdate(item);
    };

    const handleDeleteScheduleClick = async (item) => {
        showConfirmationPopUp(true);
        setDataToBeDeleted(item);
    };

    const handleRunOnDemandScheduleClick = (item) => {
        navigate(`/reports?userId=${userId}&authToken=${authToken}&jobId=${item.partitionKey}`);
    };

    const getUserSchedules = async () => {
        try {
            let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Schedules/${userId}`);
            setReportTableData(orderBy(data.data, 'createdDate', 'desc'));
        } catch (error) {
            console.error('Error fetching page load data:', error);
            setErrorPage(true);
        }
    };

    useEffect(() => {
        if (isEmpty(userId)) {
            setErrorPage(true);
        }
    }, [userId]);

    useEffect(() => {
        getUserSchedules();
    }, []);

    // Number of items to display per page
    const itemsPerPage = 6;
    let currentData;
    let totalPages = 0;
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages
    totalPages = reportTableData ? Math.ceil(reportTableData?.length / itemsPerPage) : 0;   //TODO

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the current page's data
    currentData = reportTableData?.slice(startIndex, endIndex);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0) {
            setCurrentPage(pageNumber);
        }
    };

    if (errorPage) {
        return <ErrorPage />
    } else {
        return (
            <>
                {
                    (scheduleReportPopUp || confirmationPopUp) && <div className="overlay-schedule-new" />
                }
                <Header />
                <div className="report-screen">
                    <div className="schedulereport-table">
                        <div className="button-div">
                            <button className="screport-btn d-flex align-items-center" onClick={handleScheduleNewReportClick}><img className="plus-icon" src={process.env.PUBLIC_URL + "/plus-icon.svg"} alt="plus icon" /><span>Schedule New Report</span></button>
                        </div>

                        <table className="report-table">
                            <thead className="report-th">
                                <th className="">Title <img className="arrows" /></th>
                                <th>Scheduled For <img className="arrows" /></th>
                                <th>Created Date <img className="arrows" /></th>
                                <th>Last Executed On <img className="arrows" /></th>
                                <th>Actions <img className="arrows" /></th>
                            </thead>
                            <tbody className="report-tbody">
                                {currentData && currentData.length > 0 && currentData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ReportTitle}</td>
                                        <td>{item.ScheduledDay}</td>
                                        <td>{item.createdDate.split('T')[0]}</td>
                                        <td>{item.createdDate === item.updatedDate ? "" : item.updatedDate.split('T')[0]}</td>
                                        <td className="action-td">
                                            <span className="mr-18" onClick={() => handleRunOnDemandScheduleClick(item)}>Run On Demand</span>
                                            <span onClick={() => handleEditScheduleClick(item)}><img className="edit-icon" src={process.env.PUBLIC_URL + "/edit-icon.svg"} /></span>
                                            <span onClick={() => handleDeleteScheduleClick(item)}><img className="delete-icon" src={process.env.PUBLIC_URL + "/delete-icon.svg"} /></span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="grid-pagination d-flex align-items-center justify-content-end">
                        <div className={currentPage > 1 ? "prev d-flex align-items-center" : "prev d-flex align-items-center"}><div className={currentPage > 1 ? "current" : ""} onClick={() => handlePageChange(currentPage - 1)}> <svg className="page-left-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                            <path id="down-arrow" d="M10.246,5.956a1,1,0,0,1-1.41,0l-3.59-3.54-3.54,3.54A1,1,0,1,1,.3,4.536L4.536.3a1,1,0,0,1,1.42,0l4.29,4.24a1,1,0,0,1,0,1.42Z" transform="translate(0 10.542) rotate(-90)" fill={currentPage > 1 ? "#000" : "#c2bdbd"} />
                        </svg> Prev</div>
                            <div className="page-nos">
                                {Array.from(Array(totalPages), (e, i) => {
                                    return <span className={currentPage === (i + 1) ? "current" : ""} onClick={() => handlePageChange(i + 1)}>{i + 1}</span>
                                })}
                            </div>
                            <div className={currentPage < totalPages ? "next current d-flex align-items-center" : "next d-flex align-items-center"} onClick={() => handlePageChange(currentPage + 1)}>Next<svg className="page-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                                <path id="down-arrow" d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,1,0-1.41,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59a1,1,0,0,0,0-1.42Z" transform="translate(-8.879 17.296) rotate(-90)" fill={currentPage < totalPages ? "#000" : "#c2bdbd"} />
                            </svg></div>
                        </div>
                    </div>
                </div>
                {
                    scheduleReportPopUp && <ScheduleNewReport showScheduledReportPopUp={showScheduleReportPopUp} updateUserTable={getUserSchedules} popUpData={scheduleReportPopUpDataForUpdate} popUpDataState={setScheduleReportPopUpDataForUpdate} />
                }
                {
                    confirmationPopUp && <ConfirmationPopUp userId={userId} onConfirmData={dataToBeDeleted} clearData={setDataToBeDeleted} popUpState={showConfirmationPopUp} getUserSchedules={getUserSchedules} />
                }
            </>
        )
    }
};

export default ScheduledReport;