import React, { useState } from "react";

const GridData = ({ csvData = [], headerData, onSort, dynamicCourseColumns }) => {

    // Number of items to display per page
    const itemsPerPage = 50;

    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages
    const totalPages = csvData.length > 0 ? Math.ceil(csvData.length / itemsPerPage) : 0;

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the current page's data
    const currentData = (csvData || []).slice(startIndex, endIndex);
    // Handle page change
    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="item-display grid-data">
            <div className="" style={{ overflow: 'hidden' }}>
                <div className="table-wrapper">
                    <table className="report-table">
                        <thead className="report-th">
                            {/* {headerData && headerData.length > 0 && headerData.map((item, index) => (
                                <th width="200" onClick={() => onSort(item)}>{item} <img className="arrows" src={process.env.PUBLIC_URL + "/up-down-arrows.svg"} /></th>
                            ))} */}
                            {headerData && headerData.length > 0 && headerData.map((item, index) => {
                                if (index + 1 > (headerData.length - dynamicCourseColumns.length)) {
                                    return <th width="300" >{item}</th>
                                } else {
                                    return <th width="200" onClick={() => onSort(item)}>{item} <img className="arrows" src={process.env.PUBLIC_URL + "/up-down-arrows.svg"} /></th>
                                }
                            })}
                        </thead>
                        <tbody className="report-tbody">
                            {currentData.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {Object.values(row).map((cell, cellIndex) => {
                                        if (cellIndex === 0) {
                                            return <td key={cellIndex}><div className="d-flex align-items-center robo-font"><img className="user-icon" src={process.env.PUBLIC_URL + "/User.svg"} />{cell}</div></td>
                                        } else {
                                            if (cellIndex + 1 > (Object.values(row).length - dynamicCourseColumns.length)) {
                                                if (cell?.length && cell.includes('||')) {
                                                    // completed or overdue
                                                    let status = cell.split('||')[0].trim();
                                                    let date = cell.split('||')[1];

                                                    let completed = status === 'Completed' ? true : false
                                                    return <td key={cellIndex}><span className={completed ? "completed status" : "overdue status"}>{status}</span><div className="status-info d-flex align-items-center"><img src={process.env.PUBLIC_URL + "/date-icon.svg"} /><span className="p-10">{completed ? 'Completion Date:' : 'Deadline Date:'}</span> <span className="date">{date}</span></div></td>
                                                } else if (cell?.length && cell === 'Enrolled') {
                                                    // not started/enrolled
                                                    return <td key={cellIndex}><span className="enrolled status">{cell}</span></td>
                                                } else if (cell?.length && cell === 'Not Enrolled') {
                                                    // not enrolled
                                                    return <td key={cellIndex}><span className="notenrolled status">{cell}</span></td>
                                                }
                                                return <td key={cellIndex}>{cell}</td>
                                            } else {
                                                return <td key={cellIndex}>{cell}</td>
                                            }
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="grid-pagination d-flex align-items-center justify-content-end">
                    <div className={currentPage > 1 ? "prev d-flex align-items-center" : "prev d-flex align-items-center"}><div className={currentPage > 1 ? "current" : ""} onClick={() => handlePageChange(currentPage - 1)}> <svg className="page-left-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                        <path id="down-arrow" d="M10.246,5.956a1,1,0,0,1-1.41,0l-3.59-3.54-3.54,3.54A1,1,0,1,1,.3,4.536L4.536.3a1,1,0,0,1,1.42,0l4.29,4.24a1,1,0,0,1,0,1.42Z" transform="translate(0 10.542) rotate(-90)" fill={currentPage > 1 ? "#000" : "#c2bdbd"} />
                    </svg> Prev</div>
                        <div className="page-nos">
                            {Array.from(Array(totalPages), (e, i) => {
                                return <span className={currentPage === (i + 1) ? "current" : ""} onClick={() => handlePageChange(i + 1)}>{i + 1}</span>
                            })}
                        </div>
                        {/* <div className={currentPage < totalPages ? "next current d-flex align-items-center" : "next d-flex align-items-center"} onClick={() => handlePageChange(currentPage + 1)}>Next<svg className="page-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                            <path id="down-arrow" d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,1,0-1.41,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59a1,1,0,0,0,0-1.42Z" transform="translate(-8.879 17.296) rotate(-90)" fill={currentPage < totalPages ? "#000" : "#c2bdbd"} />
                        </svg></div> */}
                        {(currentPage < totalPages) ?
                            <div className="next current d-flex align-items-center" onClick={() => handlePageChange(currentPage + 1)}>Next<svg className="page-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                                <path id="down-arrow" d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,1,0-1.41,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59a1,1,0,0,0,0-1.42Z" transform="translate(-8.879 17.296) rotate(-90)" fill={currentPage < totalPages ? "#000" : "#c2bdbd"} />
                            </svg></div> :
                            <div className="next d-flex align-items-center">Next<svg className="page-right-arrow" xmlns="http://www.w3.org/2000/svg" width="6.247" height="10.542" viewBox="0 0 6.247 10.542">
                                <path id="down-arrow" d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,1,0-1.41,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59a1,1,0,0,0,0-1.42Z" transform="translate(-8.879 17.296) rotate(-90)" fill={currentPage < totalPages ? "#000" : "#c2bdbd"} />
                            </svg></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GridData;