import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { isUndefined, isEmpty, uniq } from "lodash";

const ScheduleNewReport = ({ showScheduledReportPopUp, updateUserTable, popUpData, popUpDataState }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const token = queryParams.get('authToken');

    const dropdownRef = useRef(null);
    const schNameSuggestionRef = useRef(null);
    const schEntitySuggestionRef = useRef(null);
    const schCustomerCodeSuggestionRef = useRef(null);
    const schLosSuggestionRef = useRef(null);
    const schUuidSuggestionRef = useRef(null);
    const schStationCodeSuggestionRef = useRef(null);
    const schManagerNameSuggestionRef = useRef(null);
    const schUusSuggestionRef = useRef(null);

    const [schSearchDropdown, setSchSearchDropdown] = useState(false);
    const [courseFilterCriteria, setCourseFilterCriteria] = useState();
    const [schSearchCourseNames, setSchSearchCourseNames] = useState([]);
    const [validationError, showValidationError] = useState(false);

    const [schReportTitle, setSchReportTitle] = useState(popUpData ? popUpData.ReportTitle : '');
    const [schSearchCourse, setSchSearchCourse] = useState(popUpData ? popUpData.Courses : []);
    const [schSearchName, setSchSearchName] = useState(popUpData ? popUpData.Name : '');
    const [schSearchEntity, setSchSearchEntity] = useState(popUpData ? popUpData.EntityName : '');
    const [schSearchCustomerCode, setSchSearchCustomerCode] = useState(popUpData ? popUpData.CustomerCode : '');
    const [schSearchLosDesc, setSchSearchLosDesc] = useState(popUpData ? popUpData.LineOfServiceDescription : '');
    const [schSearchUUID, setSchSearchUUID] = useState(popUpData ? popUpData.UUID : '');
    const [schSearchStationCode, setSchSearchStationCode] = useState(popUpData ? popUpData.StationCode : '');
    const [schSearchManagerName, setSchSearchManagerName] = useState(popUpData ? popUpData.ManagerName : '');
    const [schSearchUnifiUserStatus, setSchSearchUnifiUserStatus] = useState(popUpData ? popUpData.UnifiUserStatus : '');
    const [schUserEmails, setSchUserEmails] = useState(popUpData ? popUpData.EmailId : '');
    const [schScheduleDay, setSchScheduleDay] = useState(popUpData ? popUpData.ScheduledDay : "Monday");

    const [schDisplayNameSuggestions, setSchDisplayNameSuggestions] = useState(false);
    const [schNameSuggestions, setSchNameSuggestions] = useState([]);
    const [schDisplayEntitySuggestions, setSchDisplayEntitySuggestions] = useState(false);
    const [schEntitySuggestions, setSchEntitySuggestions] = useState([]);
    const [schDisplayCustomerCodeSuggestions, setSchDisplayCustomerCodeSuggestions] = useState(false);
    const [schCustomerCodeSuggestions, setSchCustomerCodeSuggestions] = useState([]);
    const [schDisplayLOSDescSuggestions, setSchDisplayLOSDescSuggestions] = useState(false);
    const [schLosDescSuggestions, setSchLosDescSuggestions] = useState([]);
    const [schDisplayUuidSuggestions, setSchDisplayUuidSuggestions] = useState(false);
    const [schUuidSuggestions, setSchUuidSuggestions] = useState([]);
    const [schDisplayStationCodeSuggestions, setSchDisplayStationCodeSuggestions] = useState(false);
    const [schStationCodeSuggestions, setSchStationCodeSuggestions] = useState([]);
    const [schDisplayManagerNameSuggestions, setSchDisplayManagerNameSuggestions] = useState(false);
    const [schManagerNameSuggestions, setSchManagerNameSuggestions] = useState([]);
    const [schDisplayUnifiUserStatusSuggestions, setSchDisplayUnifiUserStatusSuggestions] = useState(false);
    const [schUnifiUserStatusSuggestions, setSchUnifiUserStatusSuggestions] = useState([]);

    let updateSchedule = isUndefined(popUpData) ? false : true;

    useEffect(() => {
        if(popUpData && popUpData.Courses.length > 0) {
        let courseNames = [];
        popUpData.Courses.map((ele) => {
            courseNames.push(ele);
        });
        setSchSearchCourseNames(courseNames);
    }}, [popUpData]);

    useEffect(() => {
        const getFilterCriteria = async () => {
            try {
                if (!isEmpty(userId)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/filtercriteria?token=${token}`);
                    const responseData = data.data;
                    setSchNameSuggestions(responseData.Name);
                    setSchEntitySuggestions(uniq(responseData["Entity Name"]));
                    setSchCustomerCodeSuggestions(uniq(responseData["Customer Code"]));
                    setSchLosDescSuggestions(uniq(responseData["Line Of Service Desc"]));
                    setSchUuidSuggestions(uniq(responseData["UUID"]));
                    setSchStationCodeSuggestions(uniq(responseData["Station Code"]));
                    setSchManagerNameSuggestions(uniq(responseData["Manager Name"]));
                    setSchUnifiUserStatusSuggestions(uniq(responseData["Unifi User Status"]));
                } else {
                    // setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching page load data:', error);
                // setErrorPage(true);
            }
        };
        const getFilterCriteriaCourse = async () => {
            try {
                if (!isEmpty(userId)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/FilterCriteriaCourse?token=${token}`);
                    setCourseFilterCriteria(splitArray(data.data.Course, 3));
                } else {
                    // setErrorPage(true);
                }
            } catch (error) {
                console.error('Error fetching page load data:', error);
                // setErrorPage(true);
            }
        };
        getFilterCriteria();
        getFilterCriteriaCourse();
    }, []);

    const splitArray = (originalArray, numberOfArrays) => {
        const resultArrays = new Array(numberOfArrays).fill().map(() => []);

        for (let i = 0; i < originalArray.length; i++) {
            const currentArrayIndex = i % numberOfArrays;
            resultArrays[currentArrayIndex].push(originalArray[i]);
        }
        return resultArrays;
    };

    const handleCancelPopUpClick = () => {
        showScheduledReportPopUp(false);
        popUpDataState();
    };

    const handleClearAllClick = () => {
        setSchReportTitle('');
        setSchSearchCourse([]);
        setSchSearchCourseNames([]);
        setSchSearchName('');
        setSchSearchEntity('');
        setSchSearchCustomerCode('');
        setSchSearchLosDesc('');
        setSchSearchUUID('');
        setSchSearchStationCode('');
        setSchSearchManagerName('');
        setSchSearchUnifiUserStatus('');
        setSchUserEmails('');
        setSchScheduleDay('');
    };

    const handleSchSearchCourse = () => {
        setSchSearchDropdown(true);
    };

    const handleSearchCheckBoxClick = (e, value) => {
        if (schSearchCourse.includes(value)) {
            // If the value is already in the array, remove it
            setSchSearchCourse(schSearchCourse.filter(item => item !== value));
        } else {
            // If the value is not in the array, add it
            setSchSearchCourse([...schSearchCourse, value]);
        }
        if (schSearchCourseNames.includes(value.name)) {
            // If the value is already in the array, remove it
            setSchSearchCourseNames(schSearchCourseNames.filter(item => item !== value.name));
        } else {
            // If the value is not in the array, add it
            setSchSearchCourseNames([...schSearchCourseNames, value.name]);
        }
    };

    const handleScheduleSubmitClick = async () => {
        if (schReportTitle.length > 0 && schSearchCourseNames.length > 0) {
            const newReportBody = {
                "ReportTitle": schReportTitle,
                "Course": schSearchCourseNames,
                "Name": schSearchName,
                "EntityName": schSearchEntity,
                "CustomerCode": schSearchCustomerCode,
                "LineOfServiceDesc": schSearchLosDesc,
                "uuid": schSearchUUID,
                "StationCode": schSearchStationCode,
                "Manager_Name": schSearchManagerName,
                "UnifiUserStatus": schSearchUnifiUserStatus,
                "EmailIds": schUserEmails,
                "ScheduledDay": schScheduleDay,
                "ReportType": "Schedule"
            };

            const apiUrl = updateSchedule ? `${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Schedules/${userId}?jobId=${popUpData.partitionKey}` : `${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Report/${userId}`;

            const newReportScheduleConfig = {
                url: apiUrl,
                method: updateSchedule ? 'PUT' : 'POST',
                data: newReportBody,
            };

            const newReportSchedule = await axios.request(newReportScheduleConfig);
            if (newReportSchedule.status === 200) {
                showScheduledReportPopUp(false);
                updateUserTable();
            } else {
                // show error page
                // setErrorPage(true);
            }
        } else {
            showValidationError(true);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSchSearchDropdown(false);
        }
        if (schNameSuggestionRef.current && !schNameSuggestionRef.current.contains(event.target)) {
            setSchDisplayNameSuggestions(false);
        }
        if (schEntitySuggestionRef.current && !schEntitySuggestionRef.current.contains(event.target)) {
            setSchDisplayEntitySuggestions(false);
        }
        if (schCustomerCodeSuggestionRef.current && !schCustomerCodeSuggestionRef.current.contains(event.target)) {
            setSchDisplayCustomerCodeSuggestions(false);
        }
        if (schLosSuggestionRef.current && !schLosSuggestionRef.current.contains(event.target)) {
            setSchDisplayLOSDescSuggestions(false);
        }
        if (schUuidSuggestionRef.current && !schUuidSuggestionRef.current.contains(event.target)) {
            setSchDisplayUuidSuggestions(false);
        }
        if (schStationCodeSuggestionRef.current && !schStationCodeSuggestionRef.current.contains(event.target)) {
            setSchDisplayStationCodeSuggestions(false);
        }
        if (schManagerNameSuggestionRef.current && !schManagerNameSuggestionRef.current.contains(event.target)) {
            setSchDisplayManagerNameSuggestions(false);
        }
        if (schUusSuggestionRef.current && !schUusSuggestionRef.current.contains(event.target)) {
            setSchDisplayUnifiUserStatusSuggestions(false);
        }
    };

    useEffect(() => {
        // Event listener to close the dropdown when clicking outside
        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredNameSuggestions = schNameSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchName.toLowerCase())
    );

    const handleNameSuggestionClick = (suggestion) => {
        setSchSearchName(suggestion);
        setSchDisplayNameSuggestions(false);
    };

    const filteredEntitySuggestions = schEntitySuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchEntity.toLowerCase())
    );

    const handleEntitySuggestionClick = (suggestion) => {
        setSchSearchEntity(suggestion);
        setSchDisplayEntitySuggestions(false);
    };

    const filteredCustomerCodeSuggestions = schCustomerCodeSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchCustomerCode.toLowerCase())
    );

    const handleCustomerCodeSuggestionClick = (suggestion) => {
        setSchSearchCustomerCode(suggestion);
        setSchDisplayCustomerCodeSuggestions(false);
    };

    const filteredLOSDescSuggestions = schLosDescSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchLosDesc.toLowerCase())
    );

    const handleLOSDescSuggestionClick = (suggestion) => {
        setSchSearchLosDesc(suggestion);
        setSchDisplayLOSDescSuggestions(false);
    };

    const filteredUuidSuggestions = schUuidSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchUUID.toLowerCase())
    );

    const handleUuidSuggestionClick = (suggestion) => {
        setSchSearchUUID(suggestion);
        setSchDisplayUuidSuggestions(false);
    };

    const filteredStationCodeSuggestions = schStationCodeSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchStationCode.toLowerCase())
    );

    const handleStationCodeSuggestionClick = (suggestion) => {
        setSchSearchStationCode(suggestion);
        setSchDisplayStationCodeSuggestions(false);
    };

    const filteredManagerNameSuggestions = schManagerNameSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchManagerName.toLowerCase())
    );

    const handleManagerNameSuggestionClick = (suggestion) => {
        setSchSearchManagerName(suggestion);
        setSchDisplayManagerNameSuggestions(false);
    };

    const filteredUnifiUserStatusSuggestions = schUnifiUserStatusSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(schSearchUnifiUserStatus.toLowerCase())
    );

    const handleUnifiUserStatusSuggestionClick = (suggestion) => {
        setSchSearchUnifiUserStatus(suggestion);
        setSchDisplayUnifiUserStatusSuggestions(false);
    };

    return (
        <div className="popup-screen">
            <div className="schedulereport-popup2">
                <div className="pop-body">
                    <div className="popup-header d-flex justify-content-between align-items-baseline">
                        <div className="popup-heading">Scheduled Reports</div>
                        <div className="popup-close" onClick={handleCancelPopUpClick}><img src={process.env.PUBLIC_URL + "/close-icon.svg"} /></div>
                    </div>
                    <div className="popup-content-sch">
                        {validationError ? <div className="validation-error-message">Please add report title and select atleast one course to create schedule!</div> : ""}
                        <div className="d-flex justify-content-between space-below">
                            <div className="inner-wrapper sch">
                                <div className="search-outer first-field"><input type="text" className="search-box" placeholder="Report Title" onChange={(e) => setSchReportTitle(e.target.value)} value={schReportTitle} /></div>
                            </div>
                            <div className="inner-wrapper sch right" ref={dropdownRef}>
                                <div className="search-outer first-field" ><input type="text" className="search-box-sch" placeholder={schSearchCourseNames.length > 0 ? "" : "Search Courses"} onClick={handleSchSearchCourse} /><img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} onClick={handleSchSearchCourse} /></div>

                                <div className="container-outer-sch">
                                    <div className="container-inner-sch chips-sch">
                                        {schSearchCourseNames.length > 0 && schSearchCourseNames.map((ele) => (<span>{ele}</span>))}
                                    </div>

                                </div>

                                {schSearchDropdown && <div className="search-dropdown-sch">
                                    {courseFilterCriteria && courseFilterCriteria.map((chunks, idx) => (
                                        <div className="fields-outer">
                                            {chunks.map((item, index) => (
                                                <div className="field-wrp">
                                                    <div>
                                                        <input type="checkbox" checked={schSearchCourseNames.includes(item.name)} onChange={(e) => handleSearchCheckBoxClick(e, item)} />
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </div>
                        <div className="d-flex justify-content-between space-below">
                            <div className="search-outer" ref={schNameSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Name" onChange={(e) => { setSchSearchName(e.target.value); setSchDisplayNameSuggestions(true)}} value={schSearchName} />
                                <label>Name</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchName && schDisplayNameSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredNameSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleNameSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schEntitySuggestionRef}>
                                <input type="text" className="search-box" placeholder="Entity Name" onChange={(e) => { setSchSearchEntity(e.target.value); setSchDisplayEntitySuggestions(true)}} value={schSearchEntity} />
                                <label>Entity Name</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchEntity && schDisplayEntitySuggestions &&
                                    <div className="suggestions-container">
                                        {filteredEntitySuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleEntitySuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schCustomerCodeSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Customer Code" onChange={(e) => { setSchSearchCustomerCode(e.target.value); setSchDisplayCustomerCodeSuggestions(true)}} value={schSearchCustomerCode} />
                                <label>Customer Code</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchCustomerCode && schDisplayCustomerCodeSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredCustomerCodeSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleCustomerCodeSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schLosSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Line of Service Description" onChange={(e) => { setSchSearchLosDesc(e.target.value); setSchDisplayLOSDescSuggestions(true)}} value={schSearchLosDesc} />
                                <label>Line of Service Description</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchLosDesc && schDisplayLOSDescSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredLOSDescSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleLOSDescSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-between space-below">
                            <div className="search-outer" ref={schUuidSuggestionRef}>
                                <input type="text" className="search-box" placeholder="UUID" onChange={(e) => { setSchSearchUUID(e.target.value); setSchDisplayUuidSuggestions(true)}} value={schSearchUUID} />
                                <label>UUID</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchUUID && schDisplayUuidSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredUuidSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleUuidSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schStationCodeSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Station Code" onChange={(e) => { setSchSearchStationCode(e.target.value); setSchDisplayStationCodeSuggestions(true)}} value={schSearchStationCode} />
                                <label>Station Code</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchStationCode && schDisplayStationCodeSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredStationCodeSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleStationCodeSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schManagerNameSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Manager Name" onChange={(e) => { setSchSearchManagerName(e.target.value); setSchDisplayManagerNameSuggestions(true)}} value={schSearchManagerName} />
                                <label>Manager Name</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchManagerName && schDisplayManagerNameSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredManagerNameSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleManagerNameSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className="search-outer" ref={schUusSuggestionRef}>
                                <input type="text" className="search-box" placeholder="Unifi User Status" onChange={(e) => { setSchSearchUnifiUserStatus(e.target.value); setSchDisplayUnifiUserStatusSuggestions(true)}} value={schSearchUnifiUserStatus} />
                                <label>Unifi User Status</label>
                                <img className="search-icon" src={process.env.PUBLIC_URL + "/search-icon.svg"} />
                                {
                                    schSearchUnifiUserStatus && schDisplayUnifiUserStatusSuggestions &&
                                    <div className="suggestions-container">
                                        {filteredUnifiUserStatusSuggestions.map((suggestion, index) => (
                                            <div key={index} onClick={() => handleUnifiUserStatusSuggestionClick(suggestion)} className="suggestion">
                                                {suggestion}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-between space-below">
                            <div className="search-outer">
                                <select type="text" className="search-box select" placeholder="Search UUID" onChange={(e) => setSchScheduleDay(e.target.value)} defaultValue={schScheduleDay}>
                                    <option value="Monday">Every Monday</option>
                                    <option value="Tuesday">Every Tuesday</option>
                                    <option value="Wednesday">Every Wednesday</option>
                                    <option value="Thursday">Every Thursday</option>
                                    <option value="Friday">Every Friday</option>
                                    <option value="Saturday">Every Saturday</option>
                                    <option value="Sunday">Every Sunday</option>
                                </select>
                                <img src={process.env.PUBLIC_URL + "/dwn-arrow.svg"} className="down-arrow" />
                            </div>
                            <div className="search-outer last-field">
                                <input type="text" className="search-box" placeholder="Enter Email Id(s)(Comma seperated list)" onChange={(e) => setSchUserEmails(e.target.value)} value={schUserEmails} />
                                <label>Email ID(s)</label>
                                </div>
                        </div>
                    </div>
                    <div className="popup-footer d-flex justify-content-between align-items-center">
                        <div className="note-info"><span className="Note-text">Note:</span> The report generation starts at 5 AM ET every day. Report will be sent over email once they are generated.</div>
                        <div className="d-flex align-items-center">
                            <div className="cancel-btn" onClick={handleClearAllClick}>Clear All</div>
                            {!updateSchedule ?
                                <div className="button-primary d-flex align-items-center" onClick={handleScheduleSubmitClick}><img className="load-icon" src={process.env.PUBLIC_URL + "/report-white.svg"} /><span>Schedule Report</span></div>
                                : <div className="button-primary d-flex align-items-center" onClick={handleScheduleSubmitClick}><img className="load-icon" src={process.env.PUBLIC_URL + "/report-white.svg"} /><span>Update Schedule Report</span></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ScheduleNewReport;