
import '../style.css'
import 'bootstrap/dist/css/bootstrap.min.css';


const ErrorPage = () => {
    return (
        <>
            <head>
                <title>Unify</title>
                <link rel="stylesheet" href="./style.css" />
            </head>
            <body class="error-outer">
                <div class="error-wrapper">
                    <img src={process.env.PUBLIC_URL + "/errorimg.svg"} class="error-img" alt="error-img" />
                    <div class="error-content">
                        <div class="error-title">SORRY, THE PAGE NOT FOUND</div>
                        <div class="mt-3">
                            <div class="error-title">The link you followed is probably broken</div>
                            <div class="error-title">or the page has been removed</div>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}

export default ErrorPage
