import React, { useState } from "react";

const ScheduledReportPopUp = ({ showScheduledReportPopUp, popUpData, onLoadClick }) => {
    const [checkedItem, setCheckedItem] = useState();
    
    const handleCancelPopUpClick = () => {
        showScheduledReportPopUp(false);
    };

    return (
        <div className="popup-screen">
            <div className="schedulereport-popup">
                <div className="pop-body">
                    <div className="popup-header d-flex justify-content-between align-items-baseline">
                        <div className="popup-heading">Scheduled Reports</div>
                        <div className="popup-close" onClick={handleCancelPopUpClick}><img src={process.env.PUBLIC_URL + "/close-icon.svg"} /></div>
                    </div>
                    <div className="popup-content">
                        {popUpData && popUpData.length > 0 && popUpData.map((item, index) => {
                            return <div className="ctn d-flex align-items-center"><input type="radio" name="report1" value="report1" className="popup-radio" onClick={() => setCheckedItem(item)}/><span className="report-name">{item.ReportTitle}</span></div>
                        }
                    )}
                    </div>
                    <div className="popup-footer d-flex justify-content-end align-items-center">
                        <div className="cancel-btn" onClick={handleCancelPopUpClick}>Cancel</div>
                        <div className="button-primary d-flex align-items-center" onClick={() => onLoadClick(checkedItem)}><img className="load-icon" src={process.env.PUBLIC_URL + "/load-icon.svg"}/><span>Load Report</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ScheduledReportPopUp;