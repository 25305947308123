import React, { Component, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { isUndefined, isEmpty } from "lodash";
import '../style.css'
import axios from 'axios'
import html2canvas from 'html2canvas';
import ErrorPage from "./ErrorPage";

// import { connect } from 'react-redux'
// import { compose } from 'recompose'
import 'bootstrap/dist/css/bootstrap.min.css';

const LearnerComponent = () => {
    const divRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = queryParams.get('authToken');

    const [data, setData] = useState(null);
    const [displayQRDiv, setDisplayQRDiv] = useState(true);
    const [errorPage, setErrorPage] = useState(false);

    const handleClick = async () => {
        try {
            let base64Response = await axios.post(`${process.env.REACT_APP_AZURE_WEB_APP_BASE_URL}/getUserImage`, { ...data });
            const base64Image = `data:image/jpeg;base64,${base64Response.data}`;

            // Convert base64 to Blob
            const byteCharacters = atob(base64Image.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            // Create a download link and trigger download
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'downloaded_image.jpg';
            a.click();

        } catch (err) {
            console.log("Error calling download image API", err.message);
            setErrorPage(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(!isEmpty(authToken)) {
                    let data = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/LearnerQRCode?authToken=${authToken}`);

                    setData(data.data);
                    // data.data.qrCode = 'https://unifiresourcesshreya7a9.blob.core.windows.net/unifi-user-qr-codes/qrcode_1111112.jpg';
                    if(isUndefined(data.data.qrCode) || isEmpty(data.data.qrCode)) {
                        setDisplayQRDiv(false);
                    }
                } else {
                    setErrorPage(true);
                } 
            } catch (error) {
                console.error('Error fetching data:', error);
                setErrorPage(true);
            }
        };

        fetchData();    
    }, []);

    if (errorPage) {
        return <ErrorPage/>
    } else {
        return (
            <>
                <link href="https://fonts.cdnfonts.com/css/segoe-ui-4" rel="stylesheet"/>
                <head>
                    <title>Unify</title>
                </head>
                <body className="scanner-screen" ref={divRef}>
                    <div className="desktop-hide">
                        <div className="top-section pt-5 pb-2">
                            {/* <div className="points-bar d-flex justify-content-center">
                                <img className="coin-icon" src={process.env.PUBLIC_URL + "/coin.svg"} alt="coins-icon" />
                                <div className="points-wrp">
                                    <div className="point-text">Point Earned</div>
                                    <div className="Points-earned">{data?.pointsEarned}</div>
                                </div>
                            </div> */}
                            <div className="profile-section d-flex">
                                <div>
                                    <img src={data?.avatarUrl} className="user-icon" crossorigin/>
                                </div>
                                <div className="mx-2">
                                    <div className="user-name">{data?.name}</div>
                                    <div className="position">{data?.profile}</div>
                                    <div className="divider"></div>
                                    <div className="st-code"><span className="st-cd">Station Code:</span><span className="code">{data?.stationCode}</span></div>
                                </div>
                                <div className="right-block">
                                    <div className="position">PPR:</div>
                                    <div className="prr-code">{data?.ppr}</div>
                                </div>
                            </div>
                        </div>
    
                        { displayQRDiv && <div className="scanner-details text-center">
                            <div className="scanner-wrapper">
                                <img src={data?.qrCode || null} className="scanner-code" />
                                <div className="scanner-text">Scan the QR code to view the <div>learning history. </div></div>
                                <div className="download-btn-div">
                                    <button className="down-btn" onClick={handleClick}><img src={process.env.PUBLIC_URL + "/download.svg"} className="download-icon" /><div>Download QR code</div></button>
                                </div>
                            </div>
                        </div>}
    
                        { !displayQRDiv && <div className="scanner-details text-center">
                            <div className="scanner-wrapper">
                                {/* <img src={data?.qrCode || null} className="scanner-code" alt="mobile-scanner" /> */}
                                <div className="scanner-text">QR code not yet generated.</div>
                            </div>
                        </div>}
    
                        {/* <div className="scanner-details text-center">
                            <div className="scanner-wrapper">
                                <img src={data?.qrCode || null} className="scanner-code" alt="mobile-scanner" />
                                <div className="scanner-text">Scan the QR Code to view the <div>learning history. </div></div>
                            </div>
                        </div> */}
                    </div>
                    <div className="mobile-hide">
                        <div className="d-flex vh-100">
                            <div className="left-panel">
                                <div className="points-bar-desk d-flex">
                                </div>
                                <div className="profile-section">
                                    <div>
                                        <img src={data?.avatarUrl} className="user-icon" />
                                    </div>
                                    <div className="">
                                        <div className="space-below">
                                            <div className="user-name">{data?.name}</div>
                                            <div className="position">{data?.profile}</div>
                                        </div>
                                        <div className="divider"></div>
                                        <div className="st-code"><span className="st-cd">Station Code:</span><div className="code">{data?.stationCode}</div></div>
                                    </div>
                                    <div className="right-block">
                                        <div className="position">PPR:</div>
                                        <div className="prr-code">{data?.ppr}</div>
                                    </div>
                                </div>
                            </div>
    
                            { displayQRDiv &&
                                <div className="right-panel">
                                <div className="scanner-details text-center">
                                    <div className="scanner-wrapper">
                                        <img src={data?.qrCode} className="scanner-code" />
                                        <div className="scanner-text">Scan the QR code to view the learning history.</div>
                                        <div className="download-btn-div">
                                            <button className="down-btn" onClick={handleClick}><img src={process.env.PUBLIC_URL + "/download.svg"} className="download-icon" /><div>Download QR code</div></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
    
                            { !displayQRDiv &&
                                <div className="right-panel">
                                <div className="scanner-details text-center">
                                    <div className="scanner-wrapper">
                                        <div className="scanner-text">QR code not yet generated.</div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
    
                </body>
            </>
        )
    }
}

// const mapStateToProps = () => ({

//   })
// export default compose(
//     connect(mapStateToProps, null),
//   )(learnerComponent)

export default LearnerComponent
