import React, { useState } from "react";
import axios from 'axios';

import ErrorPage from "../ErrorPage";

const ConfirmationPopUp = ({ userId, onConfirmData, clearData, popUpState, getUserSchedules }) => {

    const [errorPage, setErrorPage] = useState(false);

    const handleCancelPopUpClick = () => {
        popUpState(false);
        clearData('');
    };

    const onDeleteConfirm = async (item) => {

        const apiUrl = `${process.env.REACT_APP_AZURE_FUNCTION_BASE_URL}/api/Schedules/${userId}?jobId=${item.partitionKey}`;

        const deleteReportScheduleConfig = {
            url: apiUrl,
            method: 'DELETE'
        };
        
        const deleteReportSchedule = await axios.request(deleteReportScheduleConfig);
        if(deleteReportSchedule.status === 200) {
            getUserSchedules();
            popUpState(false);
        } else {
            // show error page
            setErrorPage(true);
        }
    };

    if(errorPage) {
        return <ErrorPage/>
    } else {
        return (
            <div className="popup-screen">
                <div className="schedulereport-popup">
                    <div className="pop-body">
                        <div className="popup-header d-flex justify-content-between align-items-baseline">
                            <div className="popup-heading">Scheduled Reports</div>
                            <div className="popup-close" onClick={handleCancelPopUpClick}><img src={process.env.PUBLIC_URL + "/close-icon.svg"} /></div>
                        </div>
                        <div className="popup-content-del">
                            <div className="popup-heading-message">Are you sure you want to delete schedule?</div>
                        </div>
                        <div className="popup-footer d-flex justify-content-end align-items-center">
                            <div className="cancel-btn" onClick={handleCancelPopUpClick}>Cancel</div>
                            <div className="button-primary d-flex align-items-center"><img className="load-icon" src={process.env.PUBLIC_URL + "/load-icon.svg"} /><span onClick={() => onDeleteConfirm(onConfirmData)}>Delete</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default ConfirmationPopUp;